// custom typefaces
import "typeface-lora"
import "typeface-ramabhadra"

import { wrapRootElement as wrap } from "./wrap-root-element"

export const wrapRootElement = wrap

const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8002/stats"
    : "https://just-some-statistik.herokuapp.com/stats"

async function addVisit({ page, browser, language, referrer, dimensions }) {
  try {
    const response = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({ page, browser, language, referrer, dimensions }),
    })
    console.log({ response })
  } catch (error) {
    console.log(error)
  }
}

function getBrowser() {
  let opr
  let safari

  // Opera 8.0+
  var isOpera =
    (!!window.opr && !!opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0

  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== "undefined"

  // Safari 3.0+ "[object HTMLElementConstructor]"
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function(p) {
      return p.toString() === "[object SafariRemoteNotification]"
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && safari.pushNotification)
    )

  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia

  // Chrome 1 - 79
  var isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

  // Edge (based on chromium) detection
  var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1

  // Blink engine detection
  var isBlink = (isChrome || isOpera) && !!window.CSS

  if (isOpera) {
    return "Opera"
  }
  if (isFirefox) {
    return "Firefox"
  }
  if (isSafari) {
    return "Safari"
  }
  if (isIE) {
    return "Internet Explorer"
  }
  if (isEdge) {
    return "Edge"
  }
  if (isChrome) {
    return "Chrome"
  }
  if (isEdgeChromium) {
    return "Edge (Chromium)"
  }
  if (isBlink) {
    return "Blink"
  }
  return "N/A"
}

export const onClientEntry = () => {
  window.onload = () => {
    const language = navigator.language
    const browser = getBrowser()
    const page = window.location.pathname
    const referrer = document.referrer
    const dimensions = window.screen.width + " x " + window.screen.height
    addVisit({ page, language, browser, referrer, dimensions })
  }
}

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation) {
    const language = navigator.language
    const browser = getBrowser()
    const page = window.location.pathname
    const referrer = document.referrer
      ? document.referrer
      : prevLocation
      ? prevLocation.href
      : "Unknown"
    const dimensions = window.screen.width + " x " + window.screen.height
    addVisit({ page, language, browser, referrer, dimensions })
  }
}
